@import "/src/assets/styles/includes/vars";
@import "/src/assets/styles/includes/margin";
@import "/src/assets/styles/includes/mixins";
@import "/src/assets/styles/includes/fonts";
@import "variables/global";

*,
*::before,
*::after {
    box-sizing: border-box;
}

html {
    font-size: 15px;
}

body,
html {
    margin: 0;
}

body {
    font-family: "Roboto", sans-serif;
    color: var(--primary);
    font-weight: 400;
    line-height: 1.3;
    background-color: var(--white);
}

a {
    text-decoration: none;
    color: inherit;
    transition: all 0.15s;
}

ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

img {
    display: block;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
}

button,
input,
textarea {
    background-color: unset;
    border: none;
    font-family: inherit;
    outline: none;
    font-size: inherit;
    color: inherit;
}

button {
    cursor: pointer;
    padding: 0;
    user-select: none;
}

.container {
    max-width: 1640px;
    width: 100%;
    margin: 0 auto;
    padding: 0 20px;
}

.app {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.main {
    flex-grow: 1;
    display: flex;
}

.mapboxgl-popup {
    max-width: unset !important;
    color: #262B40;
    pointer-events: none;
}

.marker-title {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 10px;
}

.marker-icon {
    position: absolute;
    right: 0;
    top: 0;
    transform: translate(30%, -30%);
    background-image: url("../images/trackerMap.svg");
    background-size: cover;
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.marker-bar {
    position: absolute;
    width: 4px;
    height: 50px;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    background-color: #19A872;
}

.mapboxgl-popup-content {
    font-family: "Roboto", sans-serif;
    padding: 0;
    background-color: transparent;
}

.marker-bottom {
    display: flex;
    font-size: 15px;
}

.marker-speed {
    margin-left: 15px;
    padding-left: 15px;
    position: relative;
    font-weight: 700;

    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 1px;
        height: 25px;
        background-color: #D5D5D5;
    }
}

.mapboxgl-popup-close-button, .mapboxgl-popup-tip {
    display: none;
}

.marker-wrapper {
    border-radius: 5px;
    background-color: #F5F7FB;
    border: 1px solid #D9DDE5;
    padding: 10px 15px;
    white-space: nowrap;
}

.MuiPaper-root.MuiMenu-paper {
    max-height: unset;
    overflow: hidden;
    height: auto;
}
