@import '../../assets/styles/includes/vars';
@import '../../assets/styles/includes/mixins';

.header {
    height: var(--header-height);
    padding: 10px 50px 10px 30px;
    background-color: var(--white);
    border-bottom: 1px solid var(--greyLight);
    position: relative;
    z-index: 10;
}

.headerCompany {
    max-width: 145px;
    width: 100%;
    flex-shrink: 0;
    position: relative;
}

.headerLogo {
    margin-right: 35px;
    padding-right: 35px;
    position: relative;
    flex-shrink: 0;

    &::after {
        content: '';
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 1px;
        height: 45px;
        background-color: var(--greyLight);
    }

    img {
        width: 190px;
        height: 35px;
        object-fit: contain;
    }
}

.headerNav {
    width: 100%;
    margin-right: 20px;
    height: calc(100% + 20px);
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.headerMenu {
    display: flex;
    font-size: 15px;
    height: 100%;
}

.headerMenuTitle {
    margin-bottom: 15px;
}

.headerMenuItem {
    display: flex;
    align-items: center;
    position: relative;

    & + & {
        margin-left: 20px;
    }
}

.headerMenuLink {
    display: flex;
    align-items: center;
    padding: 10px 15px;
    color: var(--black);

    &:hover {
        color: var(--secondary);
    }
}

.headerMenuLinkActive {
    font-weight: 700;

    &::after {
        content: '';
        position: absolute;
        bottom: -1px;
        width: 100%;
        height: 3px;
        background-color: var(--secondary);
        left: 0;
    }
}

.headerUser {

}

.headerUserAvatar {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    background-color: var(--blueLight);
    user-select: none;
}
